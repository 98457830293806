@font-face {
  font-family: 'Lato';
  src: url(./fonts/Lato-Regular.woff) format('woff'),
    url('./fonts/Lato-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Bold.woff') format('woff'),
    url('./fonts/Lato-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
