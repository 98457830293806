@font-face {
  font-family: Lato;
  src: url("Lato-Regular.b7f6ccdd.woff") format("woff"), url("Lato-Regular.6cf1952c.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url("Lato-Bold.83c28623.woff") format("woff"), url("Lato-Bold.3b4ae738.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

/*# sourceMappingURL=index.6a479a6c.css.map */
